import request from "@/utils/interactionService";

export async function fetchCAVVariables(options, domainId, cancelToken) {
  let allVariables = [];
  let nextPageUrl = `/interactions/v1/domains/${domainId}/variables`;

  while (nextPageUrl) {
    const response = await request({
      url: nextPageUrl,
      method: "get",
      cancelToken: cancelToken
    });
    const variablesData = response.data.items;
    allVariables = allVariables.concat(variablesData);

    // Update nextPageUrl with the next page URL from the response
    nextPageUrl = response.data.paging?.next || null;
  }

  return { data: { items: allVariables } };
}

export async function fetchCAVVariableGroups(options, domainId, cancelToken) {
  let allVariableGroups = [];
  let nextPageUrl = `/interactions/v1/domains/${domainId}/variable-groups`;

  while (nextPageUrl) {
    const response = await request({
      url: nextPageUrl,
      method: "get",
      cancelToken: cancelToken
    });
    const variableGroupsData = response.data.items;
    allVariableGroups = allVariableGroups.concat(variableGroupsData);

    // Update nextPageUrl with the next page URL from the response
    nextPageUrl = response.data.paging?.next || null;
  }

  return { data: { items: allVariableGroups } };
}
