import {
  createUser,
  createUserProfile,
  deleteUser,
  getUsers,
  getUser,
  getCurrentUser,
  mapInvitesToUserAccountMaps,
  updateUser,
  updateUserProfile,
  updateUserPassword,
  validateEmailAndToken,
  sendForgotPasswordEmail,
  resetPassword,
  validatePasswordResetEmailAndToken,
  getPasswordValidationSummary,
  revokeInvitedUser,
  reVerifyInactiveUser,
  listAccountsForUser,
  get2FA,
  forceDeleteUser,
  enableOrDisable2faBySystemUser,
  sendForgot2FAEmail,
  reset2FA,
  validate2FAResetEmailAndToken
} from "@/api/users";
import {
  formatAccountMappingToSave,
  formatUserDetailsToShow
} from "@/utils/users";
import { makeRandomId } from "@/utils/mocker";
import _ from "lodash";

const state = {
  users: [],
  accounts: [],
  user_data: [],
  current_user_data: [],
  validRequest: "",
  isProfileCreated: false,
  isUserInvited: false,
  isLoading: false,
  isLoadingAccounts: false,
  isForgotMailSent: false,
  isPasswordReset: false,
  validResetRequest: false,
  two_fa: {
    qr_code: ""
  },
  passwordPolicySummary: ""
};

const mutations = {
  SET_USERS(state, users) {
    state.users = users;
  },

  CREATE_USER(state, user) {
    state.users.push(user);
  },

  UPDATE_USER(state, { index, user }) {
    state.users.splice(index, 1, user);
  },

  DELETE_USER(state, user) {
    state.users = state.users.filter(
      oldUser => oldUser.user_id !== user.user_id
    );
  },

  REVOKE_USER(state, user) {
    state.users = state.users.filter(
      oldUser => oldUser.user_id !== user.user_id
    );
  },

  SET_VALID_REQUEST(state, isValid) {
    state.validRequest = isValid;
  },

  SET_IS_PROFILE_CREATED(state, isCreated) {
    state.isProfileCreated = isCreated;
  },

  SET_IS_INVITE_MAPPED(state, isMapped) {
    state.isUserInvited = isMapped;
  },

  SET_IS_FORGOT_MAIL_SENT(state, isSent) {
    state.isForgotMailSent = isSent;
  },

  SET_IS_PASSWORD_RESET(state, isSent) {
    state.isPasswordReset = isSent;
  },

  SET_LOADING_STATE(state, isLoading) {
    state.isLoading = isLoading;
  },

  SET_LOADING_ACCOUNTS_STATE(state, isLoading) {
    state.isLoadingAccounts = isLoading;
  },

  SET_VALID_RESET_REQUEST(state, isValid) {
    state.validResetRequest = isValid;
  },

  SET_2FA(state, data) {
    state.two_fa = data;
  },

  SET_PASSWORD_POLICY_SUMMARY(state, data) {
    state.passwordPolicySummary = data;
  },

  SET_ACCOUNTS_LIST_FOR_USER(state, data) {
    state.accounts = data;
  },

  SET_USER_DATA(state, user) {
    state.user_data = user;
  },

  SET_CURRENT_USER_DATA(state, user) {
    state.current_user_data = user;
  }
};

const getters = {
  getUser(state) {
    return user_id => _.find(state.users, { user_id });
  }
};

const actions = {
  getUsers({ commit, rootState }, options) {
    return new Promise((resolve, reject) => {
      let app = rootState.app;
      commit("SET_LOADING_STATE", true);
      let inAllAccounts = app.selectedAccountId === "all";
      getUsers(options, app.userType, inAllAccounts)
        .then(({ data }) => {
          let filterUsersData = data.data;
          if (options) {
            filterUsersData = formatUserDetailsToShow(data.data, options);
          }
          commit("SET_USERS", filterUsersData);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("SET_LOADING_STATE", false);
        });
    });
  },

  getSingleUser({ commit, rootState }, user_id) {
    return new Promise((resolve, reject) => {
      let app = rootState.app;
      commit("SET_LOADING_STATE", true);
      getUser(user_id, app.userType)
        .then(({ data }) => {
          commit("SET_USER_DATA", data.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("SET_LOADING_STATE", false);
        });
    });
  },

  getCurrentUser({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      let app = rootState.app;
      commit("SET_LOADING_STATE", true);
      getCurrentUser(app.userType)
        .then(({ data }) => {
          commit("SET_CURRENT_USER_DATA", data.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("SET_LOADING_STATE", false);
        });
    });
  },

  listAccountsForUser({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING_ACCOUNTS_STATE", true);
      listAccountsForUser(user)
        .then(({ data }) => {
          commit("SET_ACCOUNTS_LIST_FOR_USER", data.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("SET_LOADING_ACCOUNTS_STATE", false);
        });
    });
  },

  createUser({ commit, rootState }, user) {
    const userObj = formatAccountMappingToSave(user);

    // todo: fix this - temporary adjustment till things are finalized on how to invite or create user
    userObj.display_name = "temp user " + makeRandomId(2);
    userObj.password = "Inference123";
    userObj.password_confirmation = "Inference123";
    userObj.salt = "abc";
    userObj.secret_key = "abc";

    return new Promise((resolve, reject) => {
      let app = rootState.app;
      createUser(userObj, app.userType)
        .then(({ data }) => {
          commit("CREATE_USER", data.data);
          resolve(data.data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  sendForgotPasswordEmail({ commit }, user) {
    return new Promise((resolve, reject) => {
      sendForgotPasswordEmail(user)
        .then(({ data }) => {
          commit("SET_IS_FORGOT_MAIL_SENT", true);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  sendForgot2FAEmail({ commit }, user) {
    return new Promise((resolve, reject) => {
      sendForgot2FAEmail(user)
        .then(({ data }) => {
          commit("SET_IS_FORGOT_MAIL_SENT", true);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  resetPassword({ commit }, user) {
    return new Promise((resolve, reject) => {
      resetPassword(user)
        .then(({ data }) => {
          commit("SET_IS_PASSWORD_RESET", true);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  reset2FA({ commit }, user) {
    return new Promise((resolve, reject) => {
      reset2FA(user)
        .then(({ data }) => {
          commit("SET_IS_PASSWORD_RESET", true);
          resolve(data);
        })
        .catch(err => {
          commit("SET_IS_PASSWORD_RESET", false);
          reject(err);
        });
    });
  },

  validatePasswordResetRequest({ commit }, user) {
    return new Promise((resolve, reject) => {
      validatePasswordResetEmailAndToken(user)
        .then(({ data }) => {
          console.log(data);
          commit("SET_VALID_RESET_REQUEST", data.verified);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  },

  validate2FAResetRequest({ commit }, user) {
    return new Promise((resolve, reject) => {
      validate2FAResetEmailAndToken(user)
        .then(({ data }) => {
          console.log(data);
          commit("SET_VALID_RESET_REQUEST", data.verified);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  },

  updateUser({ rootState }, user) {
    const userObj = formatAccountMappingToSave(user);
    return new Promise((resolve, reject) => {
      const keys = Object.keys(user);
      let app = rootState.app;
      if (keys.includes("roles") && user.roles.data && user.roles.data.length) {
        userObj.roles = user.roles.data.map(role => role.id);
      }
      if (keys.includes("permissions") && user.permissions.length) {
        userObj.permissions = user.permissions.map(permission => permission.id);
      }
      updateUser(userObj, app.userType)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  updateUserProfile({ commit, state, dispatch }, user) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING_STATE", true);
      updateUserProfile(user)
        .then(res => {
          var index = _.findIndex(state.users, function(oldUser) {
            return oldUser.user_id === user.user_id;
          });
          commit("UPDATE_USER", { index, user: res.data.data });
          resolve(res);

          // update user info in the store to update date format and timezone
          dispatch("app/updateUserInfo", res.data.data, { root: true });
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("SET_LOADING_STATE", false);
        });
    });
  },

  updateUserPassword({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING_STATE", true);
      updateUserPassword(user)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("SET_LOADING_STATE", false);
        });
    });
  },

  async createUserMapping({ dispatch }, user) {
    const userObj = formatAccountMappingToSave(user);

    // todo: fix this - temporary adjustment till things are finalized on how to invite or create user
    userObj.display_name = "temp user " + makeRandomId(2);
    userObj.password = "Inference123";
    userObj.password_confirmation = "Inference123";
    userObj.salt = "abc";
    userObj.secret_key = "abc";
    console.log(userObj);
    await dispatch("createUser", userObj);
  },

  async updateUserMapping({ dispatch }, user) {
    const userObj = formatAccountMappingToSave(user);
    await dispatch("updateUser", userObj);
  },

  forceUpdateUser({ commit, state }, user) {
    var index = _.findIndex(state.users, function(oldUser) {
      return oldUser.user_id === user.user_id;
    });
    commit("UPDATE_USER", { index, user });
  },

  deleteUser({ commit, rootState }, user) {
    let app = rootState.app;
    return new Promise((resolve, reject) => {
      deleteUser(user, app.userType)
        .then(data => {
          commit("DELETE_USER", user);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  forceDeleteUser({ commit, rootState }, user) {
    let app = rootState.app;
    return new Promise((resolve, reject) => {
      forceDeleteUser(user, app.userType)
        .then(data => {
          commit("DELETE_USER", user);
          resolve(data);
        })
        .catch(err => {
          // 404 means the user has been deleted by the background job already so consider it a success
          if (err.isAxiosError && err.response.status === 404) {
            commit("DELETE_USER", user);
            resolve();
            return;
          }

          reject(err);
        });
    });
  },

  undoDeleteUser({ commit }, user) {
    const userObj = formatAccountMappingToSave(user);
    return new Promise((resolve, reject) => {
      // const userObj = { ...user };
      const keys = Object.keys(user);
      if (keys.includes("roles") && user.roles.data && user.roles.data.length) {
        userObj.roles = user.roles.data.map(role => role.id);
      }
      if (keys.includes("permissions") && user.permissions.length) {
        userObj.permissions = user.permissions.map(permission => permission.id);
      }
      userObj.user_status = 1;
      commit("SET_LOADING_STATE", true);
      updateUser(userObj)
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          commit("SET_LOADING_STATE", false);
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("SET_LOADING_STATE", false);
        });
    });
  },

  validateProfileRequest({ commit }, obj) {
    return new Promise((resolve, reject) => {
      validateEmailAndToken(obj.email, obj.token, obj.type)
        .then(({ data }) => {
          commit("SET_VALID_REQUEST", data.verified);
          resolve();
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  createProfileRequest({ commit }, user) {
    return new Promise((resolve, reject) => {
      createUserProfile(user)
        .then(({ data }) => {
          commit("SET_IS_PROFILE_CREATED", data.isCreated);
          resolve();
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  revokeInvitedUser({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING_STATE", true);
      revokeInvitedUser(user)
        .then(({ data }) => {
          commit("REVOKE_USER", user);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("SET_LOADING_STATE", false);
        });
    });
  },

  reVerifyInactiveUser({ commit, rootState }, user) {
    let app = rootState.app;
    return new Promise((resolve, reject) => {
      commit("SET_LOADING_STATE", true);
      reVerifyInactiveUser(user, app.userType)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("SET_LOADING_STATE", false);
        });
    });
  },

  acceptInviteRequest({ commit }, obj) {
    return new Promise((resolve, reject) => {
      mapInvitesToUserAccountMaps(obj.email, obj.token)
        .then(({ data }) => {
          commit("SET_IS_INVITE_MAPPED", data.isMapped);
          resolve();
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  /**
   * Set 2FA QR code data
   * @param {*} param0
   * @param {*} data QR code data
   */
  set2faQrCode({ commit }, data) {
    commit("SET_2FA", data);
  },

  get2FA({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.two_fa.qr_code.length) {
        resolve();
      } else {
        commit("SET_LOADING_STATE", true);
        get2FA()
          .then(({ data }) => {
            commit("SET_2FA", data.data);
            resolve();
          })
          .catch(err => {
            console.log(err);
            reject(err);
          })
          .finally(() => {
            commit("SET_LOADING_STATE", false);
          });
      }
    });
  },

  getPasswordValidationSummary({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.passwordPolicySummary.length) {
        resolve();
      } else {
        commit("SET_LOADING_STATE", true);
        getPasswordValidationSummary()
          .then(({ data }) => {
            commit("SET_PASSWORD_POLICY_SUMMARY", data.data);
            resolve();
          })
          .catch(err => {
            console.log(err);
            reject(err);
          })
          .finally(() => {
            commit("SET_LOADING_STATE", false);
          });
      }
    });
  },

  enableOrDisable2faBySystemUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING_STATE", true);
      enableOrDisable2faBySystemUser(data)
        .then(() => {
          resolve();
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("SET_LOADING_STATE", false);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
