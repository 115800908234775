import { getRecordings, deleteRecording } from "@/api/recordings";

export const state = {
  recordings: [],
  isLoading: false
};

export const mutations = {
  SET_RECORDINGS(state, recordings) {
    state.recordings = recordings;
  },

  UPDATE_LOADING_STATUS(state, isLoading) {
    state.isLoading = isLoading;
  }
};

export const actions = {
  getRecordings({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      options = { ...options };
      getRecordings(options)
        .then(async ({ data }) => {
          commit("SET_RECORDINGS", data.data);
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          reject(err);
        });
    });
  },
  deleteRecording({ commit }, record) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      deleteRecording(record)
        .then(data => {
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  }
};

export const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
