import request, { ac_headers } from "@/utils/studio7ApiService";

export function getRecordings(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/speech/recordings?" + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

export function deleteRecording(record) {
  let data = { recording_id: record.recording_id, task_id: record.task_id };
  let response = request({
    url: `/speech/recordings/${record.recording_id}`,
    method: "delete",
    data: data,
    headers: ac_headers()
  });

  return response;
}
