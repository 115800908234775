import request, { ac_headers } from "@/utils/studio7ApiService";

const reqOptions = { source: "WEB" };

export function getDataStoresCollection(
  condition,
  cancelToken = undefined,
  options = { fetch_all: 1 }
) {
  const params = new URLSearchParams({ ...options, ...reqOptions });
  const dataStoreCondition = { ...condition, guard_name: "admin" };
  return request({
    url: "/data/getdatastorecollection?" + params.toString(),
    method: "post",
    data: dataStoreCondition,
    cancelToken: cancelToken,
    headers: ac_headers()
  });
}

export function insertDataStoreDocument(data) {
  const params = new URLSearchParams(reqOptions);
  return request({
    url: `/data/data-store-collection?` + params.toString(),
    method: "post",
    data: data,
    headers: ac_headers()
  });
}

export function bulkDeleteDataStoreDocuments(data) {
  const params = new URLSearchParams(reqOptions);
  return request({
    url: `/data/bulk-delete-documents?` + params.toString(),
    method: "post",
    data: data,
    headers: ac_headers()
  });
}

export function bulkUpdateDataStoreDocuments(data) {
  const params = new URLSearchParams(reqOptions);
  return request({
    url: `/data/bulk-update-documents?` + params.toString(),
    method: "post",
    data: data,
    headers: ac_headers()
  });
}

export function getDataStoresCollectionToCSV(
  condition,
  cancelToken = undefined,
  options = { fetch_all: 1 },
  // selectedRecords,
  selectedColumn
) {
  const params = new URLSearchParams({ ...options, ...reqOptions });
  const dataStoreCondition = {
    ...condition,
    guard_name: "admin",
    export_csv: true,
    // selected_records: selectedRecords,
    selected_columns: selectedColumn
  };
  return request({
    url: "/data/get-exported-csv-url?" + params.toString(),
    method: "post",
    data: dataStoreCondition,
    cancelToken: cancelToken,
    headers: ac_headers()
  });
}
