import { getKnowledgePrompts, getKnowledgeGroups } from "@/api/knowledgeAi";

const state = {
  llm_prompts: [],
  promptIsLoading: false,
  groupIsLoading: false,
  knowledgeGroups: []
};

const mutations = {
  UPDATE_PROMPT_LOADING_STATUS(state, isLoading) {
    state.promptIsLoading = isLoading;
  },
  UPDATE_GROUP_LOADING_STATUS(state, isLoading) {
    state.groupIsLoading = isLoading;
  },
  SET_LLM_PROMPT_DATA(state, llm_prompts) {
    state.llm_prompts = llm_prompts;
  },
  SET_KNOWLEDGE_GROUP_DATA(state, knowledgeGroups) {
    state.knowledgeGroups = knowledgeGroups;
  }
};

const actions = {
  /**
   * get account information
   * @param commit
   * @param state
   * @returns {Promise<unknown>}
   */
  getKnowledgePrompts({ commit }, hideKnowledgePrompts = "false") {
    return new Promise((resolve, reject) => {
      commit("UPDATE_PROMPT_LOADING_STATUS", true);
      getKnowledgePrompts(hideKnowledgePrompts)
        .then(({ data }) => {
          commit("SET_LLM_PROMPT_DATA", data);
          commit("UPDATE_PROMPT_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          commit("SET_LLM_PROMPT_DATA", {});
          commit("UPDATE_PROMPT_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  },
  getKnowledgeGroups({ commit }) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_GROUP_LOADING_STATUS", true);
      getKnowledgeGroups()
        .then(({ data }) => {
          commit("SET_KNOWLEDGE_GROUP_DATA", data);
          commit("UPDATE_GROUP_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          commit("SET_KNOWLEDGE_GROUP_DATA", {});
          commit("UPDATE_GROUP_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  }
};

const getters = {
  llm_prompts: state => state.llm_prompts,
  promptIsLoading: state => state.promptIsLoading,
  groupIsLoading: state => state.groupIsLoading,
  knowledgeGroups: state => state.knowledgeGroups
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
