import request, { ac_headers } from "@/utils/studio7ApiService";

export function getPrompts(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/prompt/prompts?" + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

export function createPrompt(data) {
  const prompt = { ...data, guard_name: "admin" };
  return request({
    url: "/prompt/prompts",
    method: "post",
    data: prompt,
    headers: ac_headers()
  });
}

export function duplicatePrompt(prompt) {
  return request({
    url: `/prompt/prompts/duplicate/${prompt.prompt_id}`,
    method: "post",
    headers: ac_headers()
  });
}

export function updatePrompt(prompt) {
  return request({
    url: `/prompt/prompts/${prompt.prompt_id}`,
    method: "put",
    data: prompt,
    headers: ac_headers()
  });
}

export function deletePrompt(prompt) {
  return request({
    url: `/prompt/prompts/${prompt.prompt_id}`,
    method: "delete",
    data: prompt,
    headers: ac_headers()
  });
}

export function validatePrompt(name, promptType = "IVA") {
  return request({
    url: `/prompt/validate`,
    method: "post",
    data: { name, prompt_type: promptType },
    headers: ac_headers()
  });
}
