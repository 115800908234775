import socketIO from "socket.io-client";
import {
  rewriteSocketSector,
  getEncodedUrlParameterFromQuery
} from "@/utils/url";

export function socketOptions() {
  // check query string for url
  let querySocketUrl = getEncodedUrlParameterFromQuery(
    window.location.search,
    "s"
  );
  let socketUrl = process.env.VUE_APP_SOCKET_SERVER;

  if (querySocketUrl) {
    socketUrl = querySocketUrl;
  } else {
    // cloud train specific logic, normal multi sector login
    socketUrl = rewriteSocketSector(window.location.hostname, socketUrl);
  }

  ///update socket server url here, rewrite sector
  let options = {
    broadcaster: "socket.io",
    host: socketUrl,
    client: socketIO,
    transports: ["websocket", "polling", "flashsocket"]
  };

  /*
   * for cloud train need to specify the path separately otherwise ws server
   * thinks a namespace is present if added to host
   */
  if (
    process.env.VUE_APP_SOCKET_SERVER_PATH !== undefined &&
    typeof process.env.VUE_APP_SOCKET_SERVER_PATH === "string" &&
    process.env.VUE_APP_SOCKET_SERVER_PATH !== ""
  ) {
    options.path = process.env.VUE_APP_SOCKET_SERVER_PATH;
  }

  return options;
}
